<template>
    <div class="customer-selector">
        <div class="customer-selector-top">
            <van-nav-bar title="客户列表" fixed/>
        </div>
        <div class="customer-selector-search">
            <van-search placeholder="搜索一下" v-model="option.company" show-action @search="onSearch">
                <div slot="action" @click="onSearch">搜索</div>
            </van-search>
        </div>
        <div class="customer-selector-list">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="finishedText"
                    @load="onLoad"
            >
                <van-swipe-cell v-for="(item ,index) in list" v-bind:key="index">
                    <template slot="left">
                        <van-button square type="info" icon="success" text="选择" @click="onChange(item)"/>
                    </template>
                    <van-cell :border="true" :value="item.connect">
                        <template slot="title">
                            <van-tag round plain type="success" v-if="item.properties == 0">新客</van-tag>
                            <van-tag round plain v-if="item.properties == 1">舍弃</van-tag>
                            <van-tag round plain type="primary" v-if="item.properties == 2">老客</van-tag>
                            <span class="custom-title">{{item.title}}</span>
                        </template>
                    </van-cell>
                </van-swipe-cell>
            </van-list>
        </div>
        <div class="customer-selector-pagination" v-show="pages>0">
            <van-pagination
                    v-model="option.page"
                    :page-count="pages"
                    mode="simple"
                    @change="onLoad"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "customer-selector",
        data() {
            return {
                list: [],
                loading: false,
                finished: true,
                finishedText: '',
                pages: 0,
                option: {
                    page: 1,
                    company: '',
                }
            }
        },
        mounted: function () {
            this.init()
        },
        methods: {
            init() {
                this.onLoad()
            },
            onSearch() {
                this.option.page = 1
                this.onLoad()
            },
            onLoad() {
                let that = this
                that.list = []
                that.loading = true
                that.finished = false
                that.$api.company.search(that.option).then(response => {
                    that.loading = false
                    that.finished = true
                    let res = response.data
                    if (res.code == 1) {
                        if (res.data.list.length == 0) {
                            that.finishedText = '暂无数据'
                        } else {
                            that.finishedText = ''
                        }
                        that.pages = res.data.pages
                        that.list = res.data.list
                    } else {
                        that.$toast(res.msg)
                    }
                })
            },
            onChange(item) {
                let that = this
                that.$api.company.detail(item.id).then(response => {
                    let res = response.data
                    if (res.code == 1) {
                        this.$emit('onChange', res.data)
                    } else {
                        that.$notify({type: 'warning', message: res.msg})
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .customer-selector {
        height: 100%;
        width: 100%;
    }

    .customer-selector-top {
        position: fixed;
        width: 100%;
        height: 46px;
        top: 0;
    }

    .customer-selector-search{
        position: fixed;
        top: 46px;
        width: 100%;
    }

    .customer-selector-list {
        position: fixed;
        width: 100%;
        top: 100px;
        bottom: 40px;
        overflow-y: scroll;
    }

    .customer-selector-pagination {
        position: fixed;
        width: 100%;
        height: 40px;
        bottom: 0;
    }

    .van-cell__title {
        flex: 2
    }

    .van-swipe-cell .van-button {
        height: 100%;
    }
</style>

<template>
    <div class="order-input-container">
        <div class="order-input-container-top">
            <van-nav-bar :title="order.master.id > 0 ? '修改申领单' : '新增申领单'" fixed/>
        </div>
        <div class="order-input-container-form">
            <van-cell-group>
                <van-field v-model="order.master.type" label="申领类型" readonly placeholder="请输选择申领类型"
                           @click="picker.applyType=true"/>
                <van-field v-model="order.master.customer" label="申领客户" readonly placeholder="请选择申领客户"
                           @click="picker.customer=true" right-icon="arrow" v-show="order.master.typeId == 2"/>
                <van-field v-model="order.master.customer" label="申领客户" placeholder="请输入申领客户名"
                           v-show="order.master.typeId == 1"/>
                <van-field v-model="order.master.connect" label="联系人" placeholder="请输入联系人"/>
                <van-field v-model="order.master.mobile" label="联系方式" placeholder="请输入联系方式"/>
                <van-cell title="回款方式" is-link :arrow-direction="showReturnMethod ? 'down' : ''"
                          @click="showReturnMethod=!showReturnMethod"/>
                <div class="return-method" v-show="showReturnMethod">
                    <van-row v-if="returnMethodList.length>0">
                        <van-col span="8" v-for="(item, index) in mergeRturnMethod" v-bind:key="index">
                            <span :class="{'return-method-item':true,'selected':item.selected}"
                                  @click="item.selected=!item.selected">{{item.text}}</span>
                        </van-col>
                    </van-row>
                </div>
                <van-field v-model="order.master.collectionDays" label="回款期限" readonly placeholder="请输选择回款期限"
                           @click="picker.returnDays=true"/>
                <van-field label="申领明细" readonly @click="picker.goods=true" right-icon="plus"/>
                <div class="apply-goods" v-show="order.goods.length > 0">
                    <van-row class="apply-goods-title">
                        <van-col span="4">类型</van-col>
                        <van-col span="4">规格</van-col>
                        <van-col span="4">面值</van-col>
                        <van-col span="4">数量</van-col>
                        <van-col span="4">折扣</van-col>
                        <van-col span="4">金额</van-col>
                    </van-row>
                    <van-cell-group>
                        <van-swipe-cell v-for="(goods, index) in order.goods" v-bind:key="index">
                            <template slot="default">
                                <van-row class="apply-goods-item">
                                    <van-col span="4">{{goods.type}}</van-col>
                                    <van-col span="4">{{goods.spec}}</van-col>
                                    <van-col span="4">{{goods.face}}</van-col>
                                    <van-col span="4">{{goods.num}}</van-col>
                                    <van-col span="4">{{goods.discount}}</van-col>
                                    <van-col span="4">{{goods.face*goods.num*goods.discount}}</van-col>
                                </van-row>
                            </template>
                            <template slot="right">
                                <van-button square type="danger" size="small" text="删除" @click="onRemoveGoods(index)"/>
                            </template>
                        </van-swipe-cell>
                    </van-cell-group>
                </div>
                <van-field
                        v-model="order.master.remark"
                        type="textarea"
                        clearable
                        label="备注信息"
                        placeholder="请输入备注信息"
                        left-icon="my iconfont icon-beizhu"
                        maxlength="100"
                        show-word-limit
                        autosize
                />
            </van-cell-group>
        </div>
        <div class="order-input-container-btn">
            <van-button type="info" icon="passed" size="large" @click="onSubmit"
                        text="提交"/>
        </div>
        <div class="order-input-container-popup">
            <!-- 申领类型 -->
            <van-popup v-model="picker.applyType" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="申领类型"
                        :columns="applycardTypeList"
                        @confirm="onChangeApplyType"
                        @cancel="picker.applyType=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 回款期限 -->
            <van-popup v-model="picker.returnDays" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="回款期限"
                        :columns="returnDaysList"
                        @confirm="onChangeReturnDays"
                        @cancel="picker.returnDays=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 客户列表 -->
            <van-popup v-model="picker.customer" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left" :style="{width:'100%',height:'100%'}">
                <customerSelector @onChange="onChangeCustomer"/>
            </van-popup>
            <!-- 添加商品 -->
            <van-popup v-model="picker.goods" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left" :style="{width:'100%',height:'100%'}">
                <van-nav-bar title="添加明细"/>
                <van-cell-group>
                    <van-cell title="类型" is-link :value="goods.type" @click="picker.cardType=true"/>
                    <van-cell title="面值" is-link :value="goods.face" @click="picker.cardFace=true"/>
                    <van-cell title="规格" is-link :value="goods.spec" @click="picker.cardSpec=true"/>
                    <van-field
                            v-model="goods.num"
                            label="数量"
                            placeholder="请输入数量"
                            clickable
                            clearable
                            readonly
                            input-align="right"
                            maxlength="5"
                            @click="picker.cardNum=true"
                    />
                    <van-field
                            v-model="goods.discount"
                            label="折扣"
                            placeholder="请输入折扣"
                            clickable
                            clearable
                            readonly
                            input-align="right"
                            maxlength="4"
                            @click="picker.cardDiscount=true"
                    />
                    <van-field
                            :value="goods.face*goods.num*goods.discount"
                            label="金额"
                            type="number"
                            readonly
                            input-align="right"
                            maxlength="10"
                    />
                    <van-button type="info" icon="passed" size="large" @click="onAddGoods"
                                style="position:fixed;bottom:0;"
                                text="添加"/>
                </van-cell-group>
            </van-popup>
            <!-- 卡类型 -->
            <van-popup v-model="picker.cardType" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="类型"
                        :columns="cardTypeList"
                        @confirm="onChangeCardType"
                        @cancel="picker.cardType=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 卡面值 -->
            <van-popup v-model="picker.cardFace" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="面值"
                        :columns="cardFaceList"
                        @confirm="onChangeCardFace"
                        @cancel="picker.cardFace=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 卡规格 -->
            <van-popup v-model="picker.cardSpec" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="规格"
                        :columns="cardSpecList"
                        @confirm="onChangeCardSpec"
                        @cancel="picker.cardSpec=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 卡数量键盘 -->
            <van-number-keyboard
                    v-model="goods.num"
                    :show="picker.cardNum"
                    theme="custom"
                    extra-key=""
                    close-button-text="完成"
                    maxlength="5"
                    @blur="picker.cardNum=false"
                    :z-index="99999"
            />
            <!-- 卡折扣键盘 -->
            <van-number-keyboard
                    v-model="goods.discount"
                    :show="picker.cardDiscount"
                    theme="custom"
                    extra-key="."
                    close-button-text="完成"
                    maxlength="4"
                    @blur="picker.cardDiscount=false"
                    :z-index="99999"
            />
        </div>
    </div>
</template>

<script>
    import customerSelector from '../customer/selector'

    export default {
        name: 'apply-input',
        components: {
            customerSelector
        },
        props: ['node', 'param'],
        data() {
            return {
                billNo: 0,
                order: {
                    master: {
                        id: 0,
                        typeId: 0,
                        type: '',
                        customerId: 0,
                        customer: '',
                        paymentId: '',
                        paymentName: '',
                        connect: '',
                        mobile: '',
                        remark: '',
                        collectionDays: 15,
                    },
                    goods: [],
                },
                goods: {
                    typeId: 0,
                    type: '',
                    faceId: 0,
                    face: '',
                    specId: 0,
                    spec: '',
                    num: '0',
                    discount: '1',
                    remark: '',
                },
                applycardTypeList: [
                    {index: 1, text: '个人'},
                    {index: 2, text: '企业'},
                ],
                cardTypeList: [],
                cardFaceList: [],
                cardSpecList: [],
                returnMethodList: [],
                returnDaysList: [
                    {index: 1, value: 15, text: '15天'},
                    {index: 2, value: 30, text: '30天'},
                    {index: 2, value: 90, text: '90天'},
                    {index: 2, value: 180, text: '180天'}
                ],
                picker: {
                    applyType: false,
                    customer: false,
                    goods: false,
                    order: false,
                    cardType: false,
                    cardFace: false,
                    cardSpec: false,
                    cardNum: false,
                    cardDiscount: false,
                    returnDays: false,
                },
                showReturnMethod: true,
            }
        },
        computed: {
            mergeRturnMethod: function () {
                let that = this;
                let arr = that.order.master.paymentId.split(',');
                that.returnMethodList.forEach(item => {
                    item.selected = arr.indexOf(item.index.toString()) >= 0;
                });
                return that.returnMethodList;
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            // 初始化
            init() {
                let that = this;
                if (that.param && that.param.id) {
                    that.$api.apply.detail(that.param.id).then(response => {
                        let res = response.data;
                        if (res.code === 1) {
                            that.order = res.data;
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    })
                }
                that.loadReturnMethod();
                that.loadReturnDays();
                that.loadType();
                that.loadSpec();
                that.loadFace();
            },
            // 加载类型
            loadType() {
                let that = this;
                that.$api.card.loadType().then(response => {
                    let res = response.data;
                    if (res.code === 1) {
                        that.cardTypeList = res.data;
                    }
                });
            },
            // 加载面值
            loadFace() {
                let that = this;
                that.$api.card.loadFace().then(response => {
                    let res = response.data;
                    if (res.code === 1) {
                        that.cardFaceList = res.data;
                    }
                });
            },
            // 加载规格
            loadSpec() {
                let that = this;
                that.$api.card.loadSpec().then(response => {
                    let res = response.data;
                    if (res.code === 1) {
                        that.cardSpecList = res.data;
                    }
                });
            },
            // 加载回款方式
            loadReturnMethod() {
                let that = this;
                that.$api.card.loadReturnMethod().then(response => {
                    let res = response.data;
                    if (res.code === 1) {
                        res.data.forEach(item => {
                            item.selected = false;
                        });
                        that.returnMethodList = res.data;
                    }
                });
            },
            // 加载回款期限
            loadReturnDays() {
                let that = this;
                that.$api.card.loadReturnDays().then(response => {
                    let res = response.data;
                    if (res.code === 1) {
                        res.data.forEach(item => {
                            item.selected = false;
                        });
                        that.returnDaysList = res.data;
                    }
                });
            },
            // 切换申领类型
            onChangeApplyType(value) {
                this.picker.applyType = false;
                this.order.master.typeId = value.index;
                this.order.master.type = value.text;
            },
            // 切换回款期限
            onChangeReturnDays(value) {
                this.picker.returnDays = false;
                this.order.master.collectionDays = value.value;
            },
            // 切换申领类型
            onChangeCardType(value) {
                this.picker.cardType = false;
                this.goods.typeId = value.index;
                this.goods.type = value.text;
            },
            // 切换申领类型
            onChangeCardFace(value) {
                this.picker.cardFace = false;
                this.goods.faceId = value.index;
                this.goods.face = value.text;
            },
            // 切换申领类型
            onChangeCardSpec(value) {
                this.picker.cardSpec = false;
                this.goods.specId = value.index;
                this.goods.spec = value.text;
            },
            // 切换申领客户
            onChangeCustomer(customer) {
                this.picker.customer = false;
                this.order.master.customerId = customer.id;
                this.order.master.customer = customer.title;
                this.order.master.connect = customer.connect;
                this.order.master.mobile = customer.mobile;
            },
            // 添加商品
            onAddGoods() {
                let that = this;
                if (that.goods.typeId === 0) {
                    return that.$toast('请选择类型');
                }
                if (that.goods.faceId === 0) {
                    return that.$toast('请选择面值');
                }
                if (that.goods.specId === 0) {
                    return that.$toast('请选择规格');
                }
                if (that.goods.num === '0') {
                    return that.$toast('请输入数量');
                }
                if (that.order.goods.length > 0) {
                    try {
                        that.order.goods.forEach(item => {
                            if (item.typeId === that.goods.typeId && item.faceId === that.goods.faceId && item.specId === that.goods.specId) {
                                throw new Error('相同类型、规格、面值的记录已存在，请删除后重新添加');
                            }
                        });
                    } catch (e) {
                        return that.$toast(e.message);
                    }
                }
                that.order.goods.push({
                    typeId: that.goods.typeId,
                    type: that.goods.type,
                    faceId: that.goods.faceId,
                    face: that.goods.face,
                    specId: that.goods.specId,
                    spec: that.goods.spec,
                    num: that.goods.num,
                    discount: that.goods.discount,
                    remark: that.goods.remark,
                });
                that.picker.goods = false;
            },
            // 修改商品
            onChangeGoods(goods) {
                this.order.goods.push(goods);
                this.picker.goods = false;
            },
            onRemoveGoods(index) {
                this.order.goods.splice(index, 1)
            },
            // 提交申领
            onSubmit() {
                let that = this;
                if (that.order.master.typeId === 0) {
                    return that.$toast('请选择申领类型');
                }
                if (that.order.master.customer === '') {
                    let errmsg = '';
                    switch (that.order.master.typeId) {
                        case 1: // 个人
                            errmsg = '请输入申领客户名';
                            break;
                        default: // 企业
                            errmsg = '请选择申领客户';
                    }
                    return that.$toast(errmsg);
                }
                if (that.order.master.connect === '') {
                    return that.$toast('请输入联系人');
                }
                if (that.order.master.mobile === '') {
                    return that.$toast('请输入联系方式');
                }
                let returnIdArr = [];
                let returnNameArr = [];
                that.returnMethodList.forEach(item => {
                    if (item.selected) {
                        returnIdArr.push(item.index);
                        returnNameArr.push(item.text);
                    }
                });
                // 检查是否勾选回款方式
                if (returnIdArr.length === 0 || returnNameArr.length === 0) {
                    return that.$toast('请选择回款方式');
                }
                // 检查回款期限
                if (that.order.master.collectionDays === '') {
                    return that.$toast('请选择回款期限');
                }
                that.order.master.paymentId = returnIdArr.join(',');
                that.order.master.paymentName = returnNameArr.join(',');
                if (that.order.goods.length === 0) {
                    return that.$toast('请添加申领明细');
                }

                that.$api.apply.input(this.order).then(response => {
                    let res = response.data;
                    if (res.code === 1) {
                        that.$toast.success(res.msg);
                        if (that.node) {
                            that.$emit('onClose', that.node)
                        } else {
                            that.$emit('onClose')
                        }
                    } else {
                        that.$toast.fail(res.msg);
                    }
                });
            }
        },
        watch: {
            'goods.num': function (val) {
                if (val === '') {
                    this.goods.num = '0';
                } else {
                    this.goods.num = parseInt(this.goods.num).toString();
                }
            },
            'goods.discount': function (val) {
                if (val === '') {
                    this.goods.discount = '0.00';
                }
            }
        }
    }
</script>

<style scoped>
    .order-input-container {
        background-color: #f5f5f5;
        height: 100%;
        width: 100%;
    }

    .order-input-container-form {
        position: fixed;
        top: 46px;
        bottom: 50px;
        overflow-y: scroll;
        left: 0;
        right: 0;
    }

    .order-input-container-btn {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50px;
    }

    .van-cell__value {
        flex: 3
    }

    .van-swipe-cell .van-button {
        height: 100%;
    }

    .return-method {
        text-align: center;
        border-bottom: 1px solid #ebedf0;
        padding: 8px 12px;
    }

    .return-method .return-method-item {
        margin: 4px;
        border: 1px solid #eee;
        padding: 4px;
        background: #f5f5f5;
        border-radius: 5px;
        display: block;
        font-size: 14px;
    }

    .return-method .return-method-item.selected {
        border: 1px solid #1989fa;
        background: #1989fa20;
    }

    .apply-goods {
        text-align: center;
        border-bottom: 1px solid #ebedf0;
        color: #323232;
        line-height: 30px;
        font-size: 14px;
    }

    .apply-goods-title {
        background: #f5f5f5;
    }

    .apply-goods-item {
        border-bottom: 1px dashed #ebedf0;
    }

    .apply-goods-item:last-child {
        border: none;
    }

</style>
